body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0a0a0a;
}

h4{
  margin-top: 10px;
  color: #c9d1d9;
  font-size: 12px;
}

p {
  color: #c9d1d9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.home {
  display: flex;
  font-family: 'Arial', sans-serif;
  background-size: cover;
  background-position: center;
  position: relative;
  user-select: none; /* Dezactivează selecția textului */
  -webkit-tap-highlight-color: transparent; /* Elimină efectul de highlight */
  -webkit-user-select: none; /* Dezactivează selectarea textului */
  user-select: none; /* Compatibil cu alte browsere */
  outline: none; /* Elimină conturul de focus */
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(15px);
  z-index: 1;
}


.sidebar {
  min-height: 100vh; /* Face ca sidebar-ul să ocupe toată înălțimea vizibilă a paginii */
  width: 230px;
  background: #04040468;
  backdrop-filter: blur(5px);
  color: #c9d1d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  z-index: 2;
  flex-shrink: 0;
  
}

.menu-line {
  margin-left: 10px;
  margin-right: 10px;
  border-color: rgba(27, 27, 27, 0.156);
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
}

.logo img {
  width: 200px;
  height: auto;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  opacity: 0.8;
}

.menu {
  list-style: none;
  padding: 0;
  width: 100%;
  padding-top: 20%;
}

.menu a {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  transition: background 0.3s, border-radius 0.3s;
  font-size: 18px;
  font-weight: 500;
  margin: 10px;
  position: relative;
  text-decoration: none; /* Elimină sublinierea */
  color: inherit; /* Păstrează culoarea textului */
  
}

.menu a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  transition: background 0.3s, border-radius 0.3s;
  border-radius: 10px; /* Inițial border-radius */
}

.menu a:hover::before {
  background: #ffffff36;
  border-radius: 10px; /* Menține border-radius în timpul hover */
}

svg {
  margin-right: 10px;
}


.menu li svg {
  margin-right: 10px;
}

.current-section {
  background-color: #2323236e;
  border-radius: 10px;
  color: #c9d1d9;
}

.section-name {
  color: gray;
}

.wallet {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet svg {
  margin-right: 5px;
}

.content {
  flex-grow: 1;
  padding: 20px 50px;
  color: #c9d1d9;
  overflow-y: auto;
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background: #04040468;
  backdrop-filter: blur(5px);
  padding: 10px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 50%;
  color: #c9d1d9;

}



.search-icon {
  position: absolute;
  left: 15px;
  color: #c3c3c3;
  z-index: 1;
}

.search-input {
  flex-grow: 1;
  padding: 10px 10px 10px 40px;
  background: transparent;
  border: none;
  border-radius: 10px;
  color: #c9d1d9;
}

.search-input:focus {
  outline: none;
}

.icons {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #c9d1d9;

}

.featured-game {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 500px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  background-color: black;
  border-radius: 15px;
  z-index: -1;
}

.invite-progress span {
  background-color: #1e1e1e;
  padding: 10px 20px;
  color: white;
  cursor: default;
  margin-right: 15px;
  border-radius: 5px;
}

.featured-game h2 {
  margin-bottom: 10px;
  color: #fff;
  display: flex;
  justify-content: left;
}

.featured-game .tags {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
}

.game-description {
  color: #fff;
  padding-right: 400px;
}

.game-description p {
  margin-top: 15px;
}

.game-name{
  display: flex;
  text-align: left;
  font-size: 20px;
}

.featured-game .tags span {
  padding: 7px 10px;
  border-radius: 5px;
  background: #2e2e2e68;
    backdrop-filter: blur(2px);
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 10px rgba(25, 25, 25, 0.1);
}

.featured-game button {
  padding: 10px 20px;
  background: #0a0a0a68;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 10px rgba(25, 25, 25, 0.1);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: background 0.3s;
  font-size: 20px;
}


.most-popular {
  margin-top: 30px;
  color: white;
}

.most-popular-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-buttons svg{
  display: flex;
  vertical-align: middle;
  color: white;
  font-size: 25px;
}

.most-popular .games-container {
  overflow: hidden;
  position: relative;
}

.most-popular .games {
  display: flex;
  gap: 10px;
  overflow: hidden;
}

.most-popular .game {
  position: relative;
  flex: 1;
  background-size: cover;
  background-position: center;
  height: 180px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

}

.games-container {
  overflow: hidden;
  position: relative;
}

.games {
  display: flex;
  transition: transform 0.5s ease;
  width: calc(100% * 4 / 3);
  
}

.most-popular .game h3,
.most-popular .game .tags {
  color: #fff;
}

.most-popular .game .tags {
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
}

.most-popular .game .tags span {
  background: #00000068;
  backdrop-filter: blur(2px);
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 10px rgba(25, 25, 25, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
}

.most-popular .game .view {
  padding: 10px 20px;
  background: #00000068;
    backdrop-filter: blur(2px);
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 10px rgba(25, 25, 25, 0.1);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: background 0.3s;
  margin-bottom: 15px;
}

.slider-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.slider-buttons button {
  background: none;
  border: none;
  color: #c9d1d9;
  cursor: pointer;
  font-size: 24px;
  margin: 0px;
}




.background-transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.desc-title{
  margin-top: 50px;
  justify-content: left;
  display: flex;
}


.game-frame {
  display: none; /* Ascunde iframe-ul în mod implicit */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 9999;
}










/* Styling pentru Wallet Page */

.wallet-page {
  display: flex;
  user-select: none; /* Dezactivează selecția textului */
  -webkit-tap-highlight-color: transparent; /* Elimină efectul de highlight */
  -webkit-user-select: none; /* Dezactivează selectarea textului */
  user-select: none; /* Compatibil cu alte browsere */
  outline: none; /* Elimină conturul de focus */
}

.wallet-section {
  margin-top: 40px;
}

.balance-container {
  background-color: #02020256;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  height: 500px;
}

.balance-container h1 {
  font-size: 3rem;
}

.balance-container p {
  color: #0f0;
}

.dashboard {
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 50px;
  padding-top: 10px;
  background-color: black;
  border-radius: 10px;
  background-image: url('/public/dashboard.png'); /* Adaugă această linie pentru a seta imaginea de fundal */
  background-size: contain; /* Asigură-te că imaginea acoperă întregul container */
  opacity: 1;
  max-height: 400px;
}

.dashboard-elements {
  margin-right: 90%;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
}

.dashboard-elements h1{
  color: rgb(178 178 178);
}


.dashboard-procentage {
  background-color: #222222;
  border-radius: 10px;
  font-size: 20px;
  padding: 1px;
}

.chart {
  width: 100%;
  height: 100px; /* Setează o înălțime adecvată pentru chart */
  margin-top: 20px;
}

.chart-container {
  width: 100%;
  height: 100%;
  max-height: 200px; /* Adăugăm o înălțime maximă */
  position: relative;
}

.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 100px;
}

.action-button {
  background-color: #0d0d0d;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: default;
  transition: background-color 0.3s;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-container {
  display: flex;
  flex-direction: column;
}



.action-icon {
  font-size: 13px;
  margin: 0px;
}

.token-space {
  padding: 30px;
  background-color: #121212;
  border-radius: 10px;
  height: 175px; /* Setează înălțimea fixă */
  overflow-y: auto; /* Adaugă scroll vertical */
}

.transactions-space {
  padding: 30px;
  background-color: #121212;
  border-radius: 10px;
  height: 175px; /* Setează înălțimea fixă */
  overflow-y: auto; /* Adaugă scroll vertical */
}

.transaction-info{
  display: flex;
  flex-direction: column;
}

.tokens-transactions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  gap: 20px; /* Adaugă această linie pentru a crea un gap între cele două div-uri */
}

.tokens, .transactions {
  background-color: #02020256;
  padding: 20px;
  border-radius: 10px;
  width: 100%; /* Modifică de la 48% la 100% */
  height: 300px;
}

.tokens h2, .transactions h2 {
  margin-bottom: 20px;
  text-align: left;
}

.token {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.token-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.token-details {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Asigură că elementele sunt distribuite corect */
}

.token-info {
  display: flex;
  flex-direction: column;
}

.token-price-change {
  display: flex;
  align-items: center;
}

.token-name, .transaction-type {
  font-weight: bold;
  margin-bottom: 7px;
}

.token-price {
  margin-right: 10px; /* Adaugă un spațiu între preț și schimbare */
}

.token-amount {
  color: #c9d1d9;
  font-weight: bold;
  margin-bottom: 7px;
}

.token-tag {
  background-color: #3a3a3a;
  color: rgb(233, 233, 233);
  border-radius: 5px;
  margin-left: 10px;
  padding: 2px 4px;
  font-size: 14px;
  align-items: center;
  text-align: center;
  font-weight: 400px;
}

.name-tag{
  margin-bottom: 10px;
}

.transaction-details {
  color: #c9d1d9;
}

.token-value {
  color: #999;
}

.transaction-amount {
  color: #c9d1d9;
}

.token-change {
  color: rgb(0, 255, 0);
  align-self: flex-end; /* Asigură că token-change este aliniat corect */
}

.token-amount-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.transaction{
  background-color: #ffffff09;
  border-radius: 10px;
  padding: 15px 30px;
  cursor: pointer;
}

.task-section-title {
  margin-bottom: 10px;
}

.task-section-subtitle {
  margin-bottom: 30px;
  font-weight: 600;
}


/* General Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

}

.modal-content {
  background-color: #0a0a0a;
  padding: 50px;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  color: #ffffff;
  position: relative;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: rgb(28, 28, 28);
  color: #dedede;
  outline: none;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.amount-input{
  width: 100%;
}

.max-button {
  color: #ffffff;
  padding: 10px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}

.token-display {
  display: flex;
  align-items: center;
  gap: 5px;
}

.token-icon {
  width: 30px;
  height: 30px;
}

.fee-display {
  background-color: #222222;
  color: #ffffff;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 80px;
}

.send-button {
  background-color: #191919;
  color: #f1f1f1;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.cancel-button {
  background-color: transparent;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}














.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  -webkit-tap-highlight-color: transparent; /* Elimină efectul de highlight */
  -webkit-user-select: none; /* Dezactivează selectarea textului */
  user-select: none; /* Compatibil cu alte browsere */
  outline: none; /* Elimină conturul de focus */
  height: 100vh;
}

.login-card {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-image-container {
  flex: 1;
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-body {
  flex: 1;
  padding: 2rem;
  background-color: #121212ad;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
  text-align: center;
}

.login-icon {
  color: #000000;
}

.login-logo {
  font-size: 2rem;
  justify-content: center;
  display: flex;
  font-family: sans-serif;
}

.login-title {
  font-size: 14px;
  margin-bottom: 2rem;
  letter-spacing: 0.5px;
  font-family: sans-serif;
  font-weight: 10px;
}

.login-input, .register-input, .recovery-input {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  border: 1px solid #333;
  outline: none;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #f1f1f1;
  font-size: 1.125rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #ededed;
  color: #333;
}

.login-link {
  display: block;
  margin: 1rem 0;
  color: #a9a9a9;
  text-align: center;
}

.login-register {
  text-align: center;
  margin-bottom: 1rem;
  color: #eaeaea;
  margin-top: 1rem;
}

.login-register-link {
  color: #e3e3e3;
}

.login-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.login-footer-link {
  color: #a9a9a9;
  margin: 0 0.5rem;
  text-decoration: none;
}

.login-footer-link:hover {
  text-decoration: underline;
}





/* Register */

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  -webkit-tap-highlight-color: transparent; /* Elimină efectul de highlight */
  -webkit-user-select: none; /* Dezactivează selectarea textului */
  user-select: none; /* Compatibil cu alte browsere */
  outline: none; /* Elimină conturul de focus */
  height: 100vh;
}

.register-card {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.register-image-container {
  flex: 1;
}

.register-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.register-body {
  flex: 1;
  padding: 2rem;
  background-color: #121212ad;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center;
}

.register-icon {
  color: #000000;
}

.register-logo {
  font-size: 2rem;
  justify-content: center;
  display: flex;
  font-family: sans-serif;
}

.register-title {
  font-size: 14px;
  margin-bottom: 2rem;
  letter-spacing: 0.5px;
  font-family: sans-serif;
  font-weight: 10px;
}



.register-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #f1f1f1;
  font-size: 1.125rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-button:hover {
  background-color: #f1f1f1;
  color: #333;
}

.register-login {
  text-align: center;
  margin-bottom: 1rem;
  color: #eaeaea;
  margin-top: 1rem;
}

.register-login-link {
  color: #e3e3e3;
}

.register-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.register-footer-link {
  color: #a9a9a9;
  margin: 0 0.5rem;
  text-decoration: none;
}

.register-footer-link:hover {
  text-decoration: underline;
}










/* Recovery */

.recovery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
}

.recovery-card {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.recovery-image-container {
  flex: 1;
}

.recovery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recovery-body {
  flex: 1;
  padding: 2rem;
  background-color: #121212ad;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recovery-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
}

.recovery-icon {
  color: #000000;
}

.recovery-logo {
  font-size: 2rem;
  justify-content: center;
  display: flex;
  font-family: sans-serif;
}

.recovery-title {
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 2rem;
  font-family: sans-serif;
  font-weight: 10px;
}



.recovery-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #f1f1f1;
  font-size: 1.125rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.recovery-button:hover {
  background-color: #f1f1f1;
  color: #333;
}

.recovery-login {
  text-align: center;
  margin-top: 1rem;
  color: #eaeaea;
}

.recovery-login-link {
  color: #e3e3e3;
}

.recovery-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.recovery-footer-link {
  color: #a9a9a9;
  margin: 0 0.5rem;
  text-decoration: none;
}

.recovery-footer-link:hover {
  text-decoration: underline;
}




/* Balance Animation */
.balance-animation {
  animation: pulse 1s ease-in-out;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}





/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: opacity 0.5s ease-out;
}

/* Ascunde cortina după încărcare */
#preloader.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Stilizare pentru logo-ul din cortină */
#preloader img {
  width: 150px; /* Ajustează dimensiunea logo-ului după necesități */
}











/* Tap To Earn Game */

.game-section {
  background-color: #02020256;
  color: white;
  padding: 20px 30px;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
}

.game-display {
  background-image: url('/public/dashboard.png'); /* Adaugă această linie pentru a seta imaginea de fundal */
  background-size: contain;
  padding: 20px 50px;
  border-radius: 15px;
  
}

.game-xp {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Asigură că XP Label și XP Bar sunt aliniate pe orizontală */
  margin-bottom: 20px;
}

.game-xp-label {
  font-size: 24px;
  margin-right: 20px; /* Adaugă un spațiu între XP label și XP bar */
}

.xp-bar-container {
  flex-grow: 1; /* Permite xp-bar să ocupe cât mai mult spațiu */
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.xp-bar {
  width: 100%;
  background-color: #0b0b0b;
  height: 35px;
  border-radius: 10px;
  overflow: hidden;
  display: table; 
  align-items: center;
}

.xp-progress {
  background: linear-gradient(82.3deg, #77749b 10.8%, #9d99c5 94.3%);
  height: 100%;
  border-radius: 10px;
  align-items: center; /* Aliniere verticală pentru elementele interne */
  display: flex;

  /* Nu modificăm nimic în xp-progress */
}

.game-xp-value {
  font-size: 14px;
  white-space: nowrap;
  margin-left: 20px; /* Spațiu între XP value și marginea stângă */
  margin-top: 5px;
  margin-bottom: 5px;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  color: rgb(225, 225, 225);
}

.game-rank {
  font-size: 14px;
  position: absolute;
  right: 20px; /* Poziționează game-rank la dreapta în xp-bar */
}




.energy-info {
  display: flex;
  align-items: center;
  margin-left: 20px; /* Spațiu între xp-bar și energy-info */
  
}

.energy-info-mobile {
  display: flex;
  align-items: center;
  margin-left: 20px; /* Spațiu între xp-bar și energy-info */
  display: none;
  
}

.energy-icon {
  font-size: 24px;
  margin-right: 5px;
  background: linear-gradient(to bottom, #0b0b0b, #3e3e3e) !important; /* Gradient de sus în jos */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px;
  border-radius: 10px;
  color: rgb(221, 221, 221);
}


.energy-value {
  font-size: 18px;
  color: white;
  font-family: monospace;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  
}


.game-stats {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  gap: 10px; /* Adaugă spațiu între elemente */
}

.stat-box {
  backdrop-filter: blur(3px);
  background-color: #131313;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  padding: 10px 20px; /* Spațiu interior */
  border-radius: 10px; /* Colțuri rotunjite */
  text-align: center; /* Center text în interiorul fiecărui box */
  display: flex;
  align-items: center;
}


.stat-label {
  font-size: 15px;
  margin-right: 5px !important;
  font-weight: 700;
  margin: 0px;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  display: flex;
  align-items: center;
}

.stat-label svg {
  vertical-align: middle;
  font-size: 20px;
}

.stat-value {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  font-weight: 700;
  margin: 0px;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */

}



.game-tap-area {
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  padding: 50px;
  user-select: none;
  pointer-events: none;
}


.game-tap-button {
  background-image: url('/public/StarTap.png'); /* Înlocuiește cu calea către imaginea tokenului */
  background-size: cover;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  cursor: default;
  transition: transform 0.2s ease; /* Animația transformării 3D */
  user-select: none;
  pointer-events: all;
  position: relative;
}

.game-tap-button:active {
  transform: scale(0.95) perspective(1000px) rotateX(5deg) rotateY(5deg);
}



@keyframes evaporateUp {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(-250px); /* Ajustează valoarea pentru a controla distanța de evaporare */
  }
}

.tap-text {
  animation: evaporateUp 1.5s forwards; /* Aplică animația cu o durată de 1 secundă */
  color: rgb(255, 255, 255);
  font-size: 20px;
}



.game-actions-menu {
  display: -webkit-inline-box;
  background-color: #101010;
  height: 60px; /* Ajustează înălțimea pentru a se potrivi mai bine */
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  margin: 0 auto; /* Centrează meniul orizontal */
  top: 50%;
  left: 50%;
  margin: 25px;
  -webkit-tap-highlight-color: transparent; /* Elimină efectul de highlight */
  -webkit-user-select: none; /* Dezactivează selectarea textului */
  user-select: none; /* Compatibil cu alte browsere */
  outline: none; /* Elimină conturul de focus */
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  text-align: center; /* Centrează textul sub icoană */
  padding: 10px 35px;
  text-decoration: none;
}

.menu-icon {
  font-size: 24px; /* Dimensiunea icoanelor */
  margin-bottom: 5px; /* Spațiu între icoană și text */
}

.menu-label {
  font-size: 14px; /* Dimensiunea textului */
  margin: 0; /* Elimină marginea implicită */
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
}

.current-page {
  background-color: #343434;
  border-radius: 10px;
}

.rank-image-container {
  position: relative;
  width: 75px; /* Ajustează dimensiunea imaginii */
  height: 75px;
  overflow: hidden;
}

.rank-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out; /* Efectul de swap */
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
}

.rank-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
}





/* Modal rank-up */
.rankup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0a0a0a;
  color: white;
  padding: 30px 100px;
  border-radius: 10px;
  z-index: 9999;
  width: 400px;
  text-align: center;
}

.rankup-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rankup-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.rankup-modal button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  width: 200px;
}

.rankup-modal-content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}










.info-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0a0a0a;
  color: white;
  padding: 30px 100px;
  border-radius: 10px;
  z-index: 1000;
  width: 400px;
  text-align: center;
}

.info-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-modal-content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.info-modal button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  margin-top: 50px;
}





.page-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0a0a0a;
  color: white;
  padding: 30px 100px;
  border-radius: 10px;
  z-index: 1000;
  width: 400px;
  text-align: center;
}

.page-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-modal-content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.page-modal button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  width: 200px;
}





/* Spinner pentru rank-up */
.rankup-spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.game-rank-value {
  margin-left: 10px;
}

/* Stiluri pentru overlay */
.rankup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.616); /* Fundal întunecat pentru întreaga pagină */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}

/* Spinner și stiluri pentru textul de rank-up */
.loader {
  width: 350px;
  height: 180px;
  border-radius: 10px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
}

.loading {
  width: 100%;
  height: 10px;
  background: transparent;
  border-radius: 10px;
  position: relative;
}

.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 10px;
  background: rgb(240, 240, 240);
  border-radius: 10px;
  z-index: 1;
  animation: loading 0.6s alternate infinite;
}

.loader label {
  color: rgb(228, 228, 228);
  font-size: 18px;
  animation: bit 0.6s alternate infinite;
}

@keyframes bit {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    left: 0;
  }
  100% {
    left: 50%;
  }
}












/* Case Section */

@import url('https://fonts.googleapis.com/css?family=Arvo');

.raffle-roller {
  height: 162px; /* Ajustat pentru aspect 16:9 */
  position: relative;
}

.raffle-roller-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 162px; /* Ajustat pentru aspect 16:9 */
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}

.raffle-roller-container {
  display: flex;
  transition: margin-left 8s cubic-bezier(.08,.6,0,1);
}

.raffle-roller-holder:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Jumătate din lățime */
  border-right: 10px solid transparent; /* Jumătate din lățime */
  border-top: 50px solid #c6c6c64b;
  left: 50%;
  transform: translateX(-50%);
}


.item {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
  border-radius: 10px;
}

img {
  border: 0;
  vertical-align: middle;
}

.winning-item {
  border: 2px solid #66b233;
  position: relative;
  top: -1px;
  border-bottom: 4px solid #66b233;
}

.rewards {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(213.33px, 1fr));
  gap: 10px; /* Ajustează distanța dintre iteme dacă este necesar */
  padding: 10px 15px;
  background: #0b0b0b;
  border-radius: 10px;
}


.rewards .item {
  text-align: center;
  color: #fff;
  aspect-ratio: 16 / 9; /* Asigură aspectul 16:9 */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  box-sizing: border-box;
}


.rewards .item p {
  margin: 5px 0;
}

.button-container {
  text-align: center;
  margin-top: 50px;
}

.button-container button {
  background-color: #1e1e1e;
  color: white;
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none; /* Dezactivează selecția textului */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.button-container button:hover {
  background-color: #212121;
}

.case-space {
  padding: 20px 0px 40px;
  background-size: contain;
}

.contains-space {
  display: flex;
  justify-content: start;
  padding: 15px;
  background-color: #0b0b0b;
  border-radius: 10px;
  margin-bottom: 10px;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
}

p {
  margin: 0px;
}

/* Stiluri pentru modal și overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fundal negru cu opacitate */
  z-index: 999;

}

.case-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 100px;
  background-color: #0a0a0a;
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}

.case-modal-content {
  display: contents;
}

.case-modal img {
  width: 300px;
  margin: 10px 0;
  border-radius: 10px;
  margin-top: 30px;
}

.case-modal button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  width: 200px;
  
}












/* Tasks Section */
.tasks-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  color: white;
  transition: transform 0.5s ease-in-out;
}

.task-animate {
  transform: translateY(50px);
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0c0c0c;
  padding: 15px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.task-item.completed {
  background-color: #000000;
}

.task-info {
  display: flex;
  align-items: center;
  margin-left: 15px;
  text-align: start;
}

.task-icon {
  margin-right: 15px;
  font-size: 24px;
  color: #d2d2d2;
  display: flex;
  vertical-align: middle;
  margin-top: 4px;
}

.task-details {
  display: flex;
  flex-direction: column;
}

.task-details h3 {
  margin-top: 0;
  color: #d5d5d5;
  margin-bottom: 5px;
}

.task-details p {
  margin-bottom: 0;
  color: #b0b0b0;
}

.task-btn, .task-completed-btn {
  background-color: #1e1e1e;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 15px;
  user-select: none; /* Dezactivează selecția textului */
}

.collect-btn {
  background-color: rgb(222, 222, 222);
  color: #1e1e1e;
}

.completed-task-btn {
  background-color: rgb(16, 16, 16);
  color: white;
  cursor: auto;
}

/* Modal styling */
.task-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0a0a0a;
  padding: 30px;
  z-index: 1000;
  border-radius: 10px;
  max-width: 500px;
}

.task-modal-content {
  text-align: center;
}

.task-modal-content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.task-modal button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 50px;
  user-select: none; /* Dezactivează selecția textului */
  width: 200px;
}





.task-modal-ad {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0a0a0a;
  padding: 30px;
  z-index: 1000;
  border-radius: 10px;
  max-width: 500px;
}

.task-modal-ad-content {
  text-align: center;
}

.task-modal-ad-content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.task-modal-ad button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 50px;
  user-select: none; /* Dezactivează selecția textului */
  width: 200px;
}

.dim-background {
  background-color: rgba(0, 0, 0, 0.7); /* Dim background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999; /* Under modal but over content */
}














/* refferals*/

.refferals-dashboard {
  padding-bottom: 50px;
  padding-top: 10px;
  border-radius: 10px;
  background-size: contain; /* Asigură-te că imaginea acoperă întregul container */
  opacity: 1;
  max-height: 400px;
}

.referral-link-container {
  padding: 20px;
  background-color: #101010;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  width: -webkit-fill-available;
  margin-bottom: 10px;
}

.referral-link-container h3 {
  font-size: 20px;
}


.referral-link-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c0c0c;
  padding: 15px;
  border-radius: 10px;
}

.link-box-p {
  margin: 0px !important;
  color: #dbdbdb;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Textul va rămâne pe o singură linie */
  display: flex;
  position: relative;
  font-weight: bold;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  
}


.referral-link-box button {
  background-color: #0b0b0b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.referral-link-box button:hover {
  background-color: #1a1a1a;
}

.refferals-section {
  border-radius: 10px;
  text-align: center;
}

.refferals-section h1 {
  font-size: 1.5rem;
  margin-top: 20px;

}


/* Container general */
.referral-steps-container {
  text-align: center;
  padding: 20px 20px;
  background-color: #0b0b0b;
  border-radius: 10px;
}

/* Titlul secțiunii */
.referral-steps-title {
  font-size: 20px;
  color: #e0e0e0;
  margin-bottom: 30px;
  font-weight: bold;
}

/* Containerul pentru pași */
.referral-steps {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

/* Stiluri pentru fiecare pas */
.step {
  background-color: #101010;
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
}

/* Iconițele fiecărui pas */
.step-icon {
  font-size: 40px;
  color: #e2e2e2;
  margin-bottom: 15px;
}

/* Titlul fiecărui pas */
.step-title {
  font-size: 20px;
  color: #dbdbdb;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Descrierea fiecărui pas */
.step-description {
  font-size: 16px;
  color: #8e8e8e;
}

.contains-space p {
  display: flex;
  align-items: center;
}

.nav-logo {
  display: flex;
  align-items: center;
  max-width: 200px;
  display: none;
}

.nav-logo img {
  width: 70px;
  display: none;
}

.sidebar-toggler {
  display: none;
}

.user-menu-trigger-icon {
  font-size: 35px;
}

.close-area {
  display: none;
}

.close-icon {
  display: none;
}







.user-menu-trigger-icon {
  font-size: 40px;
  color: white;
  cursor: pointer;
  transition: color 0.3s;
}

.user-menu-trigger-icon:hover {
  color: #9c9c9c;
}

.user-dropdown-menu {
  position: absolute;
  top: 55%; /* Ajustează în funcție de poziția iconiței */
  right: 0;
  background-color: #0a0a0a;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  z-index: 9999;
  width: 250px;
  margin-top: 30px;
}

.user-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-dropdown-menu .menu-item {
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-dropdown-menu .menu-item:hover {
  background-color: #333;
}

.logout {
  color: #ff5c5c;
}

.logout:hover {
  background-color: #ff1a1a;
}





.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}


.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-menu {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Asigură că elementele sunt distribuite corect */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  color: rgb(239, 239, 239);
}

.user-name-tag {
  margin-bottom: 5px;
}

.address {
  font-size: 12px;
}

.user-name {
  font-size: 15px;
}






.down-menu {
  list-style: none;
  padding: 0;
  width: 100%;
  padding-top: 20%;
}

.signout-button {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  transition: background 0.3s, border-radius 0.3s;
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
  position: relative;
  text-decoration: none; /* Elimină sublinierea */
  color: inherit; /* Păstrează culoarea textului */
  width: 100%;

}

.signout-button svg {
  font-size: 20px;
}

.signout-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  transition: background 0.3s, border-radius 0.3s;
  border-radius: 10px; /* Inițial border-radius */
}

.signout-button:hover::before {
  background: #ffffff36;
  border-radius: 10px; /* Menține border-radius în timpul hover */
}

button {
  all: unset;  /* Elimină toate stilurile implicite */
  appearance: none;  /* Elimină stilurile native pentru butoane */
  cursor: pointer;  /* Setează cursorul la pointer */
  display: inline-block;  /* Setează afișarea ca un element bloc inline */
  box-sizing: border-box;  /* Asigură că dimensiunile butonului includ padding-ul și border-ul */
  text-align: center;
}

.register-message {
  margin-top: 10px;
}

.login-message {
  margin-top: 10px;
}

.recovery-message {
  margin-top: 10px;
}

.menu-logout {
  background-color: transparent;
}

.support-button {
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  color: inherit;
  border: 1px solid #b8b8b8;
  border-radius: .5rem;
  font-weight: 500;
}

.support-button svg{
  width: 18px;
  height: 18px;
  color:#b0afcf;
}

.support-button-side {
  display: none !important;
  align-items: center;
  padding: .5rem 1rem;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  color: inherit;
  border: 1px solid #b8b8b8;
  border-radius: .5rem;
  font-weight: 500;
}

.support-button-side svg{
  width: 18px;
  height: 18px;
  color:#b0afcf;
}

.referral-containers {
  display: flex;
  gap: 10px;
  padding: 0px 20px;
  background-color: #0b0b0b;
  border-radius: 10px;
  margin-top: 10px;
}

.referral-link-container h3 {
  text-align: start;
  font-size: 23px;
  margin-top: 10px;
}

.referral-link-container h5 {
  text-align: start;
  font-size: 17px;
  margin-top: 0px;
  display: none;

}

.referral-link-container p {
  text-align: start;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  line-height: 1.5;
}

.referral-link-box {
  margin-bottom: 50px;
  height: 30px;
}

.ref-btn {
  background-color: #dbdbdb;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  color: #191919;
  padding: 15px;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px;
}







    @keyframes gift-pop-in {
      0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
      }
      70% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }

    @keyframes gift-pop-out {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
      }
    }

    .gift-icon {
      animation: gift-pop-in 0.5s ease-out;
    }

    .gift-icon-exit {
      animation: gift-pop-out 0.5s ease-in forwards;
    }

    .gift-icon:hover {
      transform: translate(-50%, -50%) scale(1.1);
      transition: transform 0.2s ease;
    }

    
    .gift-icon {
      animation: gift-pop-in 0.5s ease-out;
      box-shadow: 0 0 20px rgba(255, 215, 0, 0.7);
      border-radius: 50%;
      padding: 10px;
      background: rgba(255, 217, 0, 0.484);
    }

    .gift-icon:hover {
      transform: translate(-50%, -50%) scale(1.1);
      transition: transform 0.2s ease;
    }
  
    .gift-icon svg {
      margin-right: 3px;
      margin-left: 3px;
    }



/* Bet Section */

.rocket-display {
  background-image: url('/public/space.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 100%; 
  overflow: hidden;
  position: relative;
  padding: 20px 50px;
  border-radius: 15px;
  box-shadow: rgb(117 81 44) 0px 20px 30px -10px;


}



.rocket-background-scroll {
  transition: background-position-y 1s linear;
}

.rocket-background-explosion {
  transition: none; 
}


.rocket-game-stats {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    gap: 10px; 
}

.rocket-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rocket-containers {
  display: flex;
  align-items: center;
  justify-content: center;

}

.rocket {
  width: 150px;
  height: auto;
  animation: rocketAnimation 10s linear infinite;
  z-index: 1;
}

.bet-input {
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  background-color: #171717;
  font-weight: bold;
  width: 100%;
  outline: none;
  font-size: 18px;
  
  -webkit-appearance: none; 
  -moz-appearance: textfield; 
}

.bet-input::-webkit-outer-spin-button,
.bet-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.progress-bar-container {
  width: 300px;
  height: 500px;
  position: relative;
}

.progress-bar {
  width: 35px;
  height: 100%;
  backdrop-filter: blur(3px);
  background-color: #14141461;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  position: relative;
  border-radius: 10px;
}

.progress-bar-fill {
  width: 100%;
  background: linear-gradient(82.3deg, #9d99c5 10.8%, #b0afcf 94.3%);
  position: absolute;
  bottom: 0;
  transition: height 0.1s linear;
  border-radius: 10px;
}

.mini-rocket {
  width: 30px;
  position: absolute;
  transition: bottom 0.1s linear;
  margin-left: 25px;
}

.current-x-value {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 65px;
  width: 300px;
  font-weight: bold;
  color: #dfdfdf;
  backdrop-filter: blur(3px);
  background-color: #14141461;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
  padding: 10px;
  border-radius: 10px;
}

.game-objects {
  display: flex;
  margin-bottom: 10px;
}

.user-info {
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
}

.balance, .tickets {
  background-color: #444;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}

.game-controls {
  position: relative;
  bottom: 50px;
  display: inline-table;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0px;
  border-radius: 10px;
  bottom: 0;
  width: -webkit-fill-available;
  backdrop-filter: blur(3px);
  background-color: #131313;
  margin-top: 50px;
  margin-bottom: 20px;
}

.bet-amount {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #dbdbdb;
  justify-content: center;
  margin-bottom: 0px;
  border-radius: 10px;
  padding: 5px;
}

.bet-amount button {
  background: linear-gradient(82.3deg, #77749b 10.8%, #9d99c5 94.3%);
  color: rgb(253, 253, 253);
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  display: flex;
  vertical-align: middle;
}

.bet-amount button:hover {
  background-color: lightgrey;
}

.start-bet, .bet-withdraw {
  padding: 15px 30px;
  font-size: 15px;
  font-weight: bold;
  background-color: rgb(231, 231, 231);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: rgb(22, 22, 22);
}

.game-controls-elements {
  display: flex;
}

.start-bet {
  margin-right: 5px;
  width: 100%;
}

.bet-withdraw {
  margin-left: 5px;
}





@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); } 
  50% { transform: translateX(5px); } 
  75% { transform: translateX(-5px); } 
  100% { transform: translateX(0); } 
}

@keyframes float {
  0% { transform: translateX(0); }
  50% { transform: translateX(-20px); } 
  100% { transform: translateX(0); } 
}

.rocket-shake {
  animation: shake 0.2s ease-in-out infinite; 
}

.rocket-float {
  animation: float 3s infinite ease-in-out;
}

.rocket-thrust {
  position: absolute;
  bottom: 15%; 
  transform: translateX(-50%);
  width: 100px;
  height: auto;
  z-index: 0;
}

.rocket-smoke {
  position: absolute;
  bottom: -50%; 
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  height: 1000px;
  background: radial-gradient(circle, rgba(255,255,255,0.6) 20%, rgba(255,255,255,0) 70%);
  opacity: 1;
  z-index: 0;
  animation: smoke-rise 4s forwards;
}

@keyframes smoke-rise {
  0% {
    opacity: 0.8;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50px);
  }
}


.rocket-fall {
  animation: fall-animation 2s ease-in-out forwards;
}

@keyframes fall-animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(200%);
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out; 
}


.game-countdown-modal h2 {
  color: white;
  font-size: 48px;
  margin-bottom: 20px;
}

.game-countdown-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  justify-content: center;
  flex-direction: column;
}

.countdown-number {
  font-size: 120px;
  font-weight: bold;
  color: white;
  animation: countdown-animation 1s ease-in-out;
}

@keyframes countdown-animation {
  0% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

.game-end-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
  flex-direction: column;
}

.game-end-content {
  background: rgba(255, 255, 255, 0);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.game-end-modal h2 {
  margin-bottom: 10px;
  font-size: 48px;
  color: #eaeaea;
}

.game-end-modal p {
  font-size: 20px;
}

.game-end-modal button {
  margin-top: 50px;
  padding: 10px 20px;
  background-color: #eaeaea;
  color: rgb(24, 24, 24);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  width: 200px;
}

.withdraw-message {
  position: absolute;
  top: 20px;
  border-radius: 10px;
  padding: 10px 30px;
  color: white;
  font-weight: bold;
  opacity: 0; /* Inițial invizibil */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Tranziție pentru dispariție */
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  
}

.withdraw-message.show {
  opacity: 1; /* Devine vizibil */
  animation: shake 0.5s ease, fadeOut 2s 7s forwards; /* Tremurat la apariție și fade-out */
}



@keyframes fadeOut {
  0% { opacity: 1; } /* Vizibil */
  100% { opacity: 0; transform: translateY(5px); } /* Dispare și se deplasează în jos */
}

.case-modal-content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}


.energy-zone {
  display: flex;
}

.wallet-zone {
  display: none;
}

.bet-amount button svg {
  margin: 0px;
  font-weight: bold;
  vertical-align: middle;
  display: flex;
}

.register-check {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
  margin-bottom: 20px;
}

.register-check input {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  appearance: none; /* Eliminăm stilul implicit */
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 0px;
  
}

/* Culoarea bifei când checkbox-ul este selectat */
.register-check input:checked {
  background-color: #333; /* Culoarea bifei când este bifat */
  border-color: #333;
}

/* Stilul pentru bifă */
.register-check input:checked::after {
  content: '✔';
  color: #fff; /* Culoarea bifei */
  font-size: 9px;
  position: absolute;
  left: 2px;
  top: 0px;
}

/* Eticheta va continua pe un nou rând sub checkbox */
.register-check label {
  font-size: 12px;
  color: #505050;
  line-height: 1.5;
  display: block;
}

.register-message {
  font-size: 14px;
}

.register-message a {
  color: rgb(228, 228, 228);
  font-weight: bold;
}














.promo-message {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 20px;
  font-size: 1rem;
  border-radius: 5px;
  text-align: center;
  animation: fadeInOut 3s forwards; /* Animația */
  z-index: 1000;
  width: 85%;
  font-weight: 500 !important;
}

.valid-code-message {
  background: #31313198;  
  color: rgb(226, 226, 226);
  backdrop-filter: blur(5px);
}

.invalid-code-message {
  background: #31313198;  
  color: rgb(226, 226, 226);
  backdrop-filter: blur(5px);
}

.used-code-message {
  background: #31313198;  
  color: rgb(226, 226, 226);
  backdrop-filter: blur(5px);
}

.code-input {
  border: none;
  border-radius: 10px;
  color: white;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  background-color: transparent;
  font-weight: bold;
  outline: none;
  font-size: 15px;
  width: 90%;
  
  -webkit-appearance: none; 
  -moz-appearance: textfield; 
}

.code-input::-webkit-outer-spin-button,
.code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Efect de fade-in și evaporare */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}




/* Responsive */


/* Home */

@media (max-width: 768px) {
  .home {
    flex-direction: column;
    height: auto;
  }

  .code-input {
    font-size: 13px;
  }

  .promo-message {
    bottom: 100px;
    font-size: 13px;
    padding: 15px 10px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: -100%; /* Ascuns în afara ecranului */
    height: 100vh;
    width: 300px; /* Poți ajusta lățimea */
    background-color: #0a0a0aee; /* Sau altă culoare */
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 9999;
    transition: left 0.3s ease-in-out; /* Tranziția */
    
  }

  /* Sidebar când este vizibil */
  .sidebar.show {
    left: 0; /* Apare din stânga */
  }

  .menu {
    padding-top: 0;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  .menu a {
    font-size: 14px;
    padding: 10px;
  }

  .content {
    padding: 0px;
  }

  .featured-game {
    height: 200px;
    flex-direction: column-reverse;
    padding: 10px;
    margin: 10px;
    align-items: normal;
    margin-top: 80px;
  }

  .most-popular {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 30px;
    margin-top: 20px;

  }

  .featured-game-info {
    display: block;
  }

  .popular-game-info {
    display: flow;
  }

  .game-name {
    font-size: 15px;
  }

  .featured-game button {
    font-size: 15px;
    background: #00000068;
    backdrop-filter: blur(2px);
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 10px rgba(25, 25, 25, 0.1);
    border-radius: 10px;
  }

  .most-popular .game .view {
    margin-bottom: 15px;
    font-size: 13px;
  }

  .featured-game h2 {
    font-size: 18px;
  }

  .game-description {
    padding-right: 0;
    font-size: 14px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 100px;


  }

  .most-popular .game {
    height: 150px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .most-popular .games {
    overflow: visible;
    transition: transform 0.5s ease;
    width: calc(300% * 4 / 3);
    gap: 2px;
  }

  .featured-game .tags {
    font-size: 10px;
    
  }
  .most-popular .game .tags {
    font-size: 8px;
  }

  .search-container {
    margin-right: 0;
    display: none;
  }

  .total-SGX-balance {
    display: none;
  }

  .search-input {
    padding-left: 30px;
  }

  .slider-buttons button {
    font-size: 18px;
  }
}
















































/* Tap To Earn */


@media (max-width: 768px) {

  /* Home */

  .register-container {
    margin-top: 50px;
    height: auto;
  }

  .login-container {
    margin-top: 50px;
    height: auto;
  }

  .referral-containers {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
  }

  .task-section-title {
    margin-bottom: 10px;
  }
  
  .task-section-subtitle {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 13px;
  }

  .game-section {
    background-image: url('/public/dashboard.png'); /* Adaugă această linie pentru a seta imaginea de fundal */
    background-size: contain;    color: white;
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;
    padding: 0px;
    margin-bottom: 100px;
    padding: 10px 10px;

  }
  
  .game-display {
    
    padding: 20px 10px;
    border-radius: 25px;
    background-color: #0b0b0b;
    background-image: none;
    
  }

  .game-display-2 {
    
    padding: 20px 10px;
    border-radius: 25px;
    background-color: #0b0b0b;
    background-image: none;
    
  }
  
  .game-xp {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Asigură că XP Label și XP Bar sunt aliniate pe orizontală */
    margin-bottom: 10px;
  }
  
  .game-xp-label {
    font-size: 24px;
    margin-right: 20px; /* Adaugă un spațiu între XP label și XP bar */
  }
  
  .xp-bar-container {
    flex-grow: 1; /* Permite xp-bar să ocupe cât mai mult spațiu */
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;

  }
  
  .xp-bar {
    width: 100%;
    background-color: #131313;
    height: 30px;
    border-radius: 10px;
    overflow: hidden;
    display: table; 
    align-items: center;
  }
  
  .xp-progress {
    background: linear-gradient(82.3deg, #77749b 10.8%, #9d99c5 94.3%);
    height: 100%;
    border-radius: 5px;
    align-items: center; /* Aliniere verticală pentru elementele interne */
    display: flex;
  
    /* Nu modificăm nimic în xp-progress */
  }

  .support-button-side {
    display: flex !important;
    align-items: center;
    padding: .5rem 1rem;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    color: inherit;
    border: 1px solid #b8b8b8;
    border-radius: .5rem;
    font-weight: 500;
    top: 10%;
  }

  .menu a {
    font-size: 15px;
  }

  .menu svg {
    font-size: 15px !important;
  }
  
  .support-button-side svg{
    width: 18px;
    height: 18px;
    color:#b0afcf;
  }

  .support-button {
    display: none;
    top: 10%;
  }
  
  .game-xp-value {
    font-size: 12px;
    white-space: nowrap;
    margin-left: 12px; /* Spațiu între XP value și marginea stângă */
    margin-top: 5px;
    margin-bottom: 5px;
    user-select: none; /* Dezactivează selecția textului */
    pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
    color: rgb(225, 225, 225);
  }

  .game-rank-value {
    margin-left: 5px;
  }
  
  .game-rank {
    font-size: 14px;
    position: absolute;
    right: 20px; /* Poziționează game-rank la dreapta în xp-bar */
  }
  
  
  
  
  .energy-info {
    display: flex;
    align-items: center;
    margin-left: 20px; /* Spațiu între xp-bar și energy-info */
    display: none;
    
  }

  .energy-info-mobile {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Spațiu între xp-bar și energy-info */
    margin-bottom: 10px;
    margin-right: 10px;
    justify-content: space-between;
    
  }

  .wallet-zone {
    display: flex;
    cursor: pointer;
  }

  .wallet-zone svg{
    font-size: 30px;
    color: rgb(221, 221, 221);
    vertical-align: middle;
  }
  
  .energy-icon {
    font-size: 24px;
    margin-right: 5px;
    background: transparent !important; /* Gradient de sus în jos */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0px;
    border-radius: 10px;
    color: rgb(221, 221, 221);
  }
  
  
  .energy-value {
    font-size: 18px;
    color: white;
    font-family: monospace;
    user-select: none; /* Dezactivează selecția textului */
    pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
    
  }
  
  
  .game-stats, .rocket-game-stats {
    display: inline;
    justify-content: start;
    margin-bottom: 20px;
    gap: 10px; /* Adaugă spațiu între elemente */
  }

  
  
  .stat-box {
    backdrop-filter: blur(3px);
  background-color: #131313;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
    padding: 9px 9px; /* Spațiu interior */
    border-radius: 8px; /* Colțuri rotunjite */
    text-align: center; /* Center text în interiorul fiecărui box */
    display: -webkit-inline-box;
    margin: 2px;
  }
  
  
  .stat-label {
    font-size: 11px;
    margin-bottom: 5px;
    margin-right: 5px;
    font-weight: 700;
    margin: 0px;
    user-select: none; /* Dezactivează selecția textului */
    pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  }

  .stat-label svg {
    vertical-align: middle;
    font-size: 12px;
    margin-right: 5px;
  }
  
  .stat-value {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    font-weight: 700;
    margin: 0px;
    user-select: none; /* Dezactivează selecția textului */
    pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  
  }
  
  
  
  .game-tap-area {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0px;
    user-select: none;
    pointer-events: none;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  
  .game-tap-button {
    background-image: url('/public/StarTap.png'); /* Înlocuiește cu calea către imaginea tokenului */
    background-size: cover;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    cursor: default;
    transition: transform 0.2s ease; /* Animația transformării 3D */
    user-select: none;
    pointer-events: all;
    position: relative;

  }
  
  .game-tap-button:active {
    transform: scale(0.95) perspective(1000px) rotateX(5deg) rotateY(5deg);
  }
  
  
  
  @keyframes evaporateUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-250px); /* Ajustează valoarea pentru a controla distanța de evaporare */
    }
  }
  
  .tap-text {
    animation: evaporateUp 1.5s forwards; /* Aplică animația cu o durată de 1 secundă */
    color: rgb(255, 255, 255);
    font-size: 15px;
  }
  
  
  
  /* Meniu fixat corect în partea de jos */
.game-actions-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;  /* Fixat în partea de jos */
  left: 0;          /* Pornind de la marginea stângă */
  bottom: 0;        /* Fixat în partea de jos */
  width: 100%;      /* Acoperă toată lățimea ecranului */
  height: 60px;     /* Înălțime fixă a meniului */
  background-color: #101010;  /* Fundal solid */
  z-index: 1000;    /* Asigură că meniul e deasupra altor elemente */
  padding: 0;
  margin: 0px;
  border-radius: 0px;  /* Rotunjire doar în colțurile de sus */
  top: auto;
  padding-bottom: 20px;
}

/* Iteme din meniu */
.menu-item {
  flex: 1;
  text-align: center;
  color: #fff;
  cursor: pointer;
  padding: 10px 0;
  font-size: 14px;
}

/* Iconița activă sau curentă */
.menu-item.current-page {
  color: #9d99c5;  /* Evidențiază butonul activ */
}



  
  
  .menu-icon {
    font-size: 20px; /* Dimensiunea icoanelor */
    margin-bottom: 5px; /* Spațiu între icoană și text */
  }
  
  .menu-label {
    font-size: 14px; /* Dimensiunea textului */
    margin: 0; /* Elimină marginea implicită */
    user-select: none; /* Dezactivează selecția textului */
    pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  }
  
  .current-page {
    background-color: #343434;
    border-radius: 5px;
  }
  
  .rank-image-container {
    position: relative;
    width: 55px; /* Ajustează dimensiunea imaginii */
    height: 55px;
    overflow: hidden;
  }
  
  .rank-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out; /* Efectul de swap */
    user-select: none; /* Dezactivează selecția textului */
    pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  }
  
  .rank-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
  }
  
  
  
  
  
  /* Modal rank-up */
  .rankup-modal {
    position: fixed;
    bottom: -100%; /* Inițial, este ascuns în afara ecranului */
    left: 50%;
    transform: translateX(-50%);
    background-color: #0a0a0a;
    color: white;
    padding: 20px;
    border-radius: 50px 50px 0 0; /* Colțuri rotunjite doar în partea de sus */
    z-index: 9999 !important;
    width: 100%; /* Se extinde pe toată lățimea ecranului */
    max-width: 768px; /* Pentru a se potrivi bine pe telefoane */
    text-align: center;
    transition: bottom 0.5s ease-in-out; /* Animația de apariție */
    top: 50%;
  }
  
  .rankup-modal.show {
    bottom: 0; /* Se afișează în partea de jos a ecranului */
  }
  
  .rankup-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }
  
  .rankup-image {
    width: 120px;
    height: 120px;
    margin-bottom: 20px; /* Spațiu între imagine și text */
    margin-top: 20px;
  }
  
  .rankup-modal button {
    background-color: #dedede;
    color: rgb(28, 28, 28);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%; /* Se potrivește mai bine pe ecrane mici */
  }
  





  /* Modal Game Info */
  .info-modal {
    position: fixed;
    bottom: -100%; /* Inițial, este ascuns în afara ecranului */
    left: 50%;
    transform: translateX(-50%);
    background-color: #0a0a0a;
    color: white;
    padding: 20px;
    border-radius: 50px 50px 0 0; /* Colțuri rotunjite doar în partea de sus */
    z-index: 9999 !important;
    width: 100%; /* Se extinde pe toată lățimea ecranului */
    max-width: 768px; /* Pentru a se potrivi bine pe telefoane */
    text-align: center;
    transition: bottom 0.5s ease-in-out; /* Animația de apariție */
    top: 73%;
  }
  
  .info-modal.show {
    bottom: 0; /* Se afișează în partea de jos a ecranului */
  }
  
  .info-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }









  .page-modal {
    position: fixed;
    bottom: -100%; /* Inițial, este ascuns în afara ecranului */
    left: 50%;
    transform: translateX(-50%);
    background-color: #0a0a0a;
    color: white;
    padding: 20px;
    border-radius: 50px 50px 0 0; /* Colțuri rotunjite doar în partea de sus */
    z-index: 9999 !important;
    width: 100%; /* Se extinde pe toată lățimea ecranului */
    max-width: 768px; /* Pentru a se potrivi bine pe telefoane */
    text-align: center;
    transition: bottom 0.5s ease-in-out; /* Animația de apariție */
    top: 66%;
  }
  
  .page-modal.show {
    bottom: 0; /* Se afișează în partea de jos a ecranului */
  }
  
  .page-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }

  .page-modal-content button {
    background-color: #dedede;
    color: rgb(28, 28, 28);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%; /* Se potrivește mai bine pe ecrane mici */
  }



  .info-modal-content p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .rankup-modal-content p {
    font-size: 14px;
  }

  .task-modal-content p {
    font-size: 14px;
  }

  .task-modal-ad-content p {
    font-size: 14px;
  }

  .case-modal-content p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .page-modal-content p {
    font-size: 14px;
  }
  
  .info-modal button {
    background-color: #dedede;
    color: rgb(28, 28, 28);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%; /* Se potrivește mai bine pe ecrane mici */
  }
  
  
  
  /* Spinner pentru rank-up */
  .rankup-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  
  
  /* Stiluri pentru overlay */
  .rankup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fundal întunecat pentru întreaga pagină */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
  }
  
  /* Spinner și stiluri pentru textul de rank-up */
  .loader {
    width: 350px;
    height: 180px;
    border-radius: 10px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 30px;
    user-select: none; /* Dezactivează selecția textului */
    pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  }
  
  .loading {
    width: 100%;
    height: 10px;
    background: transparent;
    border-radius: 10px;
    position: relative;
  }
  
  .loading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 5px;
    background: rgb(240, 240, 240);
    border-radius: 10px;
    z-index: 1;
    animation: loading 0.6s alternate infinite;
  }
  
  .loader label {
    color: rgb(228, 228, 228);
    font-size: 18px;
    animation: bit 0.6s alternate infinite;
  }
  
  @keyframes bit {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes loading {
    0% {
      left: 0;
    }
    100% {
      left: 50%;
    }
  }













/* Referral Steps */
.referral-steps-container {
  text-align: center;
  padding: 0px 20px;
  background-color: #0b0b0b;
  border-radius: 10px;
}

/* Titlul secțiunii */
.referral-steps-title {
  font-size: 15px;
  color: #e0e0e0;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Containerul pentru pași */
.referral-steps {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}

/* Stiluri pentru fiecare pas */
.step {
  width: 100%;
  padding: 20px;
  background-color: #131313;
}

.step:hover {
  transform: translateY(-5px);
}

.referral-link-container h3 {
  font-size: 13px;
  margin-bottom: 20px;
}

.referral-link-container h5 {
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: start;
  color: #dbdbdb;
  font-weight: 500;
}

.referral-link-container {
  padding: 10px 20px;
  background-color: #131313;
  margin-top: 10px;
}

/* Iconițele fiecărui pas */
.step-icon {
  font-size: 25px;
  color: #e2e2e2;
  margin-bottom: 10px;
}

/* Titlul fiecărui pas */
.step-title {
  font-size: 15px;
  color: #dbdbdb;
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: 0px;
}

/* Descrierea fiecărui pas */
.step-description {
  font-size: 12px;
  color: #c9d1d9;
  font-weight: 500;
  line-height: 1.5;
}

.referral-link-box p {
  font-size: 13px;
  left: 0%;
}

.referral-link-box {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  justify-content: center;
  height: 45px;
  padding: 0px;
  background-color: #0a0a0a;
  margin-top: 20px;
  margin-bottom: 30px;

}

.ref-btn {
  background-color: #dbdbdb;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  color: #191919;
  padding: 10px;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 10px;
}

.step-icon svg {
  margin: 0px
}

.referral-link-container h3 {
  color: #dbdbdb;
  text-align: start;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

.referral-link-container p {
  font-size: 12px;
  text-align: start;
  letter-spacing: 0.8px;
  line-height: 1.5;
  text-wrap: pretty;
}



.code-input {
  text-align: center;
  width: 90%;
}

.contains-space p {
  display: flex;
  align-items: center;
}

.tokens h2, .transactions h2 {
  font-size: 16px;
}

















/* Tasks Section */
.tasks-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  color: white;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #131313;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.task-item.completed {
  background-color: #000000;
}

.task-info {
  display: flex;
  align-items: center;
  margin-left: 5px;
  text-align: start;
}

.task-icon {
  margin-right: 5px;
  font-size: 16px;
  color: #d2d2d2;
  margin-top: 2px;
}

.task-details {
  display: flex;
  flex-direction: column;
}

.task-details h3 {
  margin-top: 0;
  color: #d5d5d5;
  margin-bottom: 5px;
  font-size: 14px;
}

.task-details p {
  margin-bottom: 0;
  color: #b0b0b0;
  font-size: 13px;
}

.task-btn, .task-completed-btn {
  background-color: #1e1e1e;
  border: none;
  padding: 10px 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 5px;
  user-select: none; /* Dezactivează selecția textului */
  font-size: 11px;
}

.collect-btn {
  background-color: rgb(222, 222, 222);
  color: #1e1e1e;
}

.completed-task-btn {
  background-color: rgb(16, 16, 16);
  color: white;
  cursor: auto;
}

/* Modal styling */
.task-modal {
  position: fixed;
  bottom: -100%; /* Inițial, ascuns în afara ecranului */
  left: 50%;
  transform: translateX(-50%);
  background-color: #0a0a0a;
  padding: 20px;
  z-index: 9999 !important; /* Se afișează deasupra tuturor elementelor */
  border-radius: 50px 50px 0 0; /* Colțuri rotunjite doar în partea de sus */
  max-width: 768px; /* Dimensiunea maximă a modalului pe ecrane mici */
  width: 100%;
  text-align: center;
  transition: bottom 0.5s ease-in-out; /* Animația de apariție de jos în sus */
  top: 68%;
}

.task-modal-ad {
  position: fixed;
  bottom: -100%; /* Inițial, ascuns în afara ecranului */
  left: 50%;
  transform: translateX(-50%);
  background-color: #0a0a0a;
  padding: 20px;
  z-index: 9999 !important; /* Se afișează deasupra tuturor elementelor */
  border-radius: 50px 50px 0 0; /* Colțuri rotunjite doar în partea de sus */
  max-width: 768px; /* Dimensiunea maximă a modalului pe ecrane mici */
  width: 100%;
  text-align: center;
  transition: bottom 0.5s ease-in-out; /* Animația de apariție de jos în sus */
  top: 68%;
}

.task-modal.show {
  bottom: 0; /* Se afișează în partea de jos a ecranului */
}

.task-modal-ad.show {
  bottom: 0; /* Se afișează în partea de jos a ecranului */
}

.task-modal-content {
  text-align: center;
  padding: 0px 20px;
}

.task-modal-ad-content {
  text-align: center;
  padding: 0px 20px;
}

.task-modal-content h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px !important;
}

.task-modal-ad-content h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px !important;
}

.task-modal button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%; /* Potrivit pentru ecrane mici */
  user-select: none; /* Dezactivează selecția textului */
}

.task-modal-ad button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%; /* Potrivit pentru ecrane mici */
  user-select: none; /* Dezactivează selecția textului */
}

/* Overlay pentru a acoperi tot restul paginii */
.task-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundal semi-transparent */
  z-index: 9998; /* Sub modal, dar deasupra restului paginii */
  display: none; /* Ascuns inițial */
}

.task-modal-ad-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundal semi-transparent */
  z-index: 9998; /* Sub modal, dar deasupra restului paginii */
  display: none; /* Ascuns inițial */
}

.task-modal-overlay.show {
  display: block; /* Afișat când modalul este activ */
}

.task-modal-ad-overlay.show {
  display: block; /* Afișat când modalul este activ */
}


.dim-background {
  background-color: rgba(0, 0, 0, 0.7); /* Dim background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999; /* Under modal but over content */
}

.game-display h2{
  font-size: 15px;
}





















/* Case Section */

@import url('https://fonts.googleapis.com/css?family=Arvo');

.raffle-roller {
  height: 162px; /* Ajustat pentru aspect 16:9 */
  position: relative;
}

.raffle-roller-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 162px; /* Ajustat pentru aspect 16:9 */
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}

.raffle-roller-container {
  display: flex;
  transition: margin-left 8s cubic-bezier(.08,.6,0,1);
}

.raffle-roller-holder:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Jumătate din lățime */
  border-right: 10px solid transparent; /* Jumătate din lățime */
  border-top: 50px solid #c6c6c64b;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: #131313;
}


.item {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
  border-radius: 10px;
  
}

img {
  border: 0;
  vertical-align: middle;
}

.winning-item {
  border: 2px solid #66b233;
  position: relative;
  top: -1px;
  border-bottom: 4px solid #66b233;
}

.rewards {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Dimensiuni mai mici pentru carduri */
  gap: 8px; /* Ajustăm distanța dintre iteme */
  padding: 10px 0px;
}

.rewards .item {
  width: 100% !important; /* Cardurile ocupă întreaga lățime a coloanei disponibile */
  height: 100px !important; /* Ajustează înălțimea */
  aspect-ratio: unset; /* Elimină aspect-ratio fix pentru a lăsa cardurile să fie mai flexibile */
  background-size: cover;
  background-position: center;
  margin: 0 auto; /* Centrăm cardurile */
}


.rewards .item p {
  margin: 5px 0;
}

.button-container {
  text-align: center;
  margin-top: 50px;
}

.button-container button {
  background-color: #131313;
  color: white;
  border: none;
  padding: 10px 30px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none; /* Dezactivează selecția textului */
}

.button-container button:hover {
  background-color: #212121;
}

.case-space {
  padding: 20px 0px 40px;
  background-size: contain;
}

.contains-space {
  display: flex;
  justify-content: start;
  padding: 15px;
  background-color: #131313;
  border-radius: 10px;
  margin-bottom: 10px;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
  height: 10px;
  font-size: 13px;
}

p {
  margin: 0px;
}

/* Stiluri pentru modal și overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fundal negru cu opacitate */
  z-index: 999;
}

.case-modal {
  position: fixed;
  bottom: -100%; /* Inițial, ascuns în afara ecranului */
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #0a0a0a;
  border-radius: 50px 50px 0 0; /* Colțuri rotunjite doar în partea de sus */
  z-index: 9999 !important; /* Deasupra tuturor elementelor */
  max-width: 768px; /* Dimensiunea maximă a modalului pe ecrane mici */
  width: 100%;
  text-align: center;
  transition: bottom 0.5s ease-in-out; /* Animația de apariție de jos în sus */
  top: 65.5%;
}



.case-modal-content {
  padding: 0px 20px;
  display: block;
}

.case-modal-content h2 {
  font-size: 20px;
}

.case-modal.show {
  bottom: 0; /* Se afișează în partea de jos a ecranului */
}

.case-modal img {
  width: 100%; /* Ajustabil pe lățimea completă */
  max-width: 300px; /* Maxima dimensiune pe ecrane mari */
  margin: 10px 0;
  border-radius: 10px;
  margin-top: 30px;
}

.case-modal button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%; /* Se potrivește pe ecrane mici */
}









.game-end-modal {
  max-width: 768px; /* Dimensiunea maximă a modalului pe ecrane mici */
  bottom: -100%;
  top: 70%;
  width: -webkit-fill-available;
  background-color: #0a0a0a;
  border-radius: 20px 20px 0 0; /* Colțuri rotunjite doar în partea de sus */
  position: fixed;
  z-index: 9999 !important; 
  flex-direction: column;
  padding: 20px;
  text-align: center;
  transition: bottom 0.5s ease-in-out; /* Animația de apariție de jos în sus */
  justify-content: flex-start;
}

.game-end-content {
  background: rgba(255, 255, 255, 0);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.game-end-modal h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #eaeaea;
}

.game-end-modal p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.game-end-modal button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #dedede;
  color: rgb(28, 28, 28);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
}















/* Styling pentru Wallet Page */

.wallet-page {
  display: flex;
  user-select: none; /* Dezactivează selecția textului */
}

.wallet-section {
  margin-top: 75px;
  background-image: url('/public/dashboard.png'); /* Adaugă această linie pentru a seta imaginea de fundal */
  background-size: contain; /* Asigură-te că imaginea acoperă întregul container */
  border-radius: 25px;
  padding: 20px 10px;
  margin-bottom: 25px;
}

.balance-container {
  background-color: #02020200;
  padding: 0px;
  border-radius: 10px;
  text-align: center;
  height: auto;
  padding-bottom: 10px;
}

.balance-container h1 {
  font-size: 3rem;
}

.balance-container p {
  color: #0f0;
}

.dashboard {
  padding: 20px 20px;
  background-color: #0b0b0b;
  border-radius: 25px;
  max-height: 400px;
  background-image: none;
  opacity: 1;
}

.action-container h4 {
  font-size: 11px;
}

.dashboard-elements {
  margin-right: 90%;
  user-select: none; /* Dezactivează selecția textului */
  pointer-events: none; /* Dezactivează interacțiunea cu elementele interne */
}

.dashboard-elements h1{
  color: rgb(234, 234, 234);
  font-size: 30px;
  margin-top: 10px

}


.dashboard-procentage {
  background-color: #222222;
  border-radius: 10px;
  font-size: 20px;
  padding: 1px;
}

.chart {
  width: 100%;
  height: 100px; /* Setează o înălțime adecvată pentru chart */
  margin-top: 20px;
}

.chart-container {
  width: 100%;
  height: 100%;
  max-height: 200px; /* Adăugăm o înălțime maximă */
  position: relative;
}

.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 100px;
}

.action-button {
  background-color: #0d0d0d;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  font-size: 13px;
}

.user-name-tag {
  margin-bottom: 3px;
}

.user-image {
  margin-right: 13px;
}

.action-container {
  display: flex;
  flex-direction: column;
}

.action-button:hover {
  background-color: #0d0d0d;
}

.action-icon {
  font-size: 13px;
  margin: 0px;
}

.token-space {
  padding: 10px;
  background-color: #121212;
  border-radius: 10px;
  height: 150px; /* Setează înălțimea fixă */
  overflow-y: auto; /* Adaugă scroll vertical */
}

.transactions-space {
  padding: 10px;
  background-color: #121212;
  border-radius: 10px;
  height: 150px; /* Setează înălțimea fixă */
  overflow-y: auto; /* Adaugă scroll vertical */
}

.transaction-info{
  display: flex;
  flex-direction: column;
}

.tokens-transactions {
  display: inline;
  justify-content: space-between;
  margin-top: 40px;
  gap: 20px; /* Adaugă această linie pentru a crea un gap între cele două div-uri */
}

.tokens, .transactions {
  background-color: #02020256;
  padding: 20px;
  border-radius: 10px;
  width: auto; /* Modifică de la 48% la 100% */
  height: 225px;
  opacity: 1;
  margin-top: 0px;
}

.tokens {
  margin-bottom: 10px;
  border-radius: 25px;
}

.transactions {
  margin-bottom: 20px;
  border-radius: 25px;
}

.transaction-details {
  font-size: 13px;
}

.transaction-amount {
  font-size: 13px;
}

.tokens h2, .transactions h2 {
  margin-bottom: 20px;
  text-align: left;
}

.token {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.token-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}

.token-details {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Asigură că elementele sunt distribuite corect */
}

.token-info {
  display: flex;
  flex-direction: column;
}

.token-price-change {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.token-name, .transaction-type {
  font-weight: bold;
  margin-bottom: 7px;
  font-size: 15px;
}

.token-price {
  margin-right: 10px; /* Adaugă un spațiu între preț și schimbare */
  font-size: 13px;
}

.token-amount {
  color: #c9d1d9;
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 13px;
}

.token-tag {
  background-color: #3a3a3a;
  color: rgb(233, 233, 233);
  border-radius: 5px;
  margin-left: 10px;
  padding: 2px 4px;
  font-size: 13px;
  align-items: center;
  text-align: center;
  font-weight: 400px;
}

.name-tag{
  margin-bottom: 5px;
}

.transaction-details {
  color: #c9d1d9;
}

.token-value {
  color: #999;
  font-size: 13px;
}

.transaction-amount {
  color: #c9d1d9;
}

.token-change {
  color: rgb(0, 255, 0);
  align-self: flex-end; /* Asigură că token-change este aliniat corect */
}

.token-amount-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.transaction{
  background-color: #ffffff09;
  border-radius: 10px;
  padding: 15px 10px;
  cursor: pointer;
}


/* General Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  position: fixed;
  bottom: -100%; /* Inițial ascuns în afara ecranului */
  left: 50%;
  transform: translateX(-50%);
  background-color: #0a0a0a;
  padding: 20px;
  border-radius: 50px 50px 0 0; /* Colțuri rotunjite doar în partea de sus */
  z-index: 9999 !important; /* Deasupra tuturor elementelor */
  max-width: 768px; /* Dimensiunea maximă a modalului pe ecrane mici */
  width: 100%; /* Se potrivește pe lățimea ecranului */
  color: #ffffff;
  text-align: center;
  transition: bottom 0.5s ease-in-out; /* Animație de apariție de jos în sus */
  top: 42%;
}

.send-modal-content {
  padding: 0px 20px;
}

.send-modal-content h2{
  font-size: 20px;
}

.modal-body p {
  text-align: start;
}

.modal-content.show {
  bottom: 0; /* Se afișează în partea de jos a ecranului */
}


h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: rgb(28, 28, 28);
  color: #dedede;
  outline: none;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.amount-input{
  width: 100%;
}

.max-button {
  color: #ffffff;
  padding: 10px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}

.token-display {
  display: flex;
  align-items: center;
  gap: 5px;
}

.token-icon {
  width: 30px;
  height: 30px;
}

.fee-display {
  background-color: #222222;
  color: #ffffff;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.modal-footer {
  display: block;
  align-items: end;
  margin-top: 80px;
}

.send-button {
  background-color: #dedede;
  color: rgb(28, 28, 28);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.cancel-button {
  background-color: rgb(28, 28, 28);
  color: #dedede;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.user-menu-trigger-icon {
  margin-right: 0px;
  font-size: 30px;
}

.toggler-button {
  margin-right: 0px;
  font-size: 30px;
}

.nav-logo {
  display: flex;
  align-items: center;
  max-width: 200px;
  display: initial;
}

.nav-logo img {
  width: 175px;
  display: initial;
  opacity: 0.8;
}

.header {
  background: #04040468;
  backdrop-filter: blur(5px);
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px;
  width: -webkit-fill-available;
  position: fixed;
  z-index: 9999;
}

.tap-to-earn {
  margin-top: 75px;
}

.sidebar-toggler {
  display: contents;
}

.most-popular-header h2 {
  font-size: 17px;
}

.desc-title {
  font-size: 17px;
  margin-top: 20px;
}

.logo {
 display: none;
}

.logo img {
  display: none;
}

.close-area {
  position: absolute;
  top: 10px; /* Poziționează iconița de închidere la o mică distanță de partea superioară */
  right: 30px; /* Poziționează iconița la dreapta */
  cursor: pointer; /* Schimbă cursorul la pointer pentru a indica faptul că poate fi apăsată */
  z-index: 1001; /* Se asigură că iconița de închidere va apărea peste alte elemente din sidebar */
  display: flex;
}

.close-icon {
  font-size: 20px; /* Dimensiunea iconiței */
  display: flex;
  margin: 0px;
}

.invite-progress span {
  background-color: #1e1e1e;
  padding: 10px 10px;
  color: white;
  cursor: default;
  margin-right: 5px;
  border-radius: 5px;
  font-size: 11px;
}

.login-card {
  display: inline;
  margin: 0px 10px;
  border-radius: 10px;
}

.login-image {
  width: 100%;
  height: 200px;
}

.login-logo{ 
  font-size: 25px;
}

.login-header {
  margin-bottom: 0;
  justify-content: start;
}

.login-body {
  padding: 2rem 1.3rem;
}

.login-button{ 
  font-size: 14px;
}








.register-card {
  display: inline;
  margin: 0px 10px;
  border-radius: 10px;
}

.register-image {
  width: 100%;
  height: 200px;
}

.register-logo{ 
  font-size: 25px;
}

.register-header {
  margin-bottom: 0;
  justify-content: start;
}

.register-body {
  padding: 2rem 1.3rem;
}

.register-button{ 
  font-size: 14px;
}

.register-title, .login-title {
  margin-top: 5px;
  font-family: unset;
  font-weight: 500;
}

.register-input, .login-input, .recovery-input {
  font-size: 11px;
  padding: 10px;
}



.loader-title {
  font-size: 18px !important;
  width: 90%;
  top: 20% !important;
  line-height: 30px;
}

.loader-footer {
  width: 100%;
}

.loader-button {
  padding: 12px 30px !important;
  font-size: 15px !important;
  color: #f4f4f4 !important;
}




.recovery-card {
  display: inline;
  margin: 0px 10px;
  border-radius: 10px;
}

.recovery-image {
  width: 100%;
  height: 200px;
}

.recovery-logo{ 
  font-size: 25px;
}

.recovery-header {
  margin-bottom: 0;
  justify-content: start;
}

.recovery-body {
  padding: 2rem 1.3rem;
}

.recovery-button{ 
  font-size: 14px;
}

.register-check label {
  font-size: 10px;
}

.register-check input {
  width: 13px;
  height: 13px;
  margin-left: 0px;
  margin-right: 5px;
}

.register-check {
  margin: 15px 0px;
}

.register-check input:checked::after {
  top: 1px;
  left: 0px;
  color: rgb(234, 234, 234);
}







/* Bet 768 */

.progress-bar-container{
  width: 100px;
  height: 200px;
}

.rocket{
  width: 70px;
  height: auto;
}

.current-x-value{
  font-size: 20px;
  width: 100px;
  position: relative;
  top: 0%;
  padding: 10px 5px;
}

.game-controls{
  padding: 0px;
  width: -webkit-fill-available;
  margin-bottom: 0px;
  margin-top: 20px;
}

.bet-amount {
  font-size: 15px;
  margin-bottom: 0px;
  padding: 0px;
}

.bet-input {
  font-size: 17px;
}

.bet-amount button {
  padding: 10px;
}

.start-bet {
  padding: 10px;
  font-size: 13px;
  margin-top: 10px;
  border-radius: 5px;
}

.game-objects {
  margin-top: 0px;
  margin-bottom: 0px;
}

.progress-bar {
  width: 15px;
}

.mini-rocket {
  margin-left: 15px;
}

.rocket-thrust {
  width: 50px;
  bottom: 10%;
}

.rocket-display {
  background-size: 180%; /* Zoom pe background */
  background-position: 50% 100%; /* Începe de jos și centrăm pe orizontală */
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #0b0b0b;  
  margin-top: 15px;
  margin-bottom: 50px;
  box-shadow: rgb(117 81 44) 0px 20px 30px -10px;
}

.withdraw-message {
  font-size: 11px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 0%;
  margin: 50px 10px;
  border-radius: 10px;
}

.withdraw-message.show {
  opacity: 1; /* Devine vizibil */
  animation: shake 0.5s ease, fadeOut 2s 7s forwards; /* Tremurat la apariție și fade-out */
}



@keyframes fadeOut {
  0% { opacity: 1; } /* Vizibil */
  100% { opacity: 0; transform: translateY(5px); } /* Dispare și se deplasează în jos */
}

.mini-rocket {
  width: 20px;
}

.game-end-content h2 {
  font-size: 24px;
}

.game-end-content p {
  font-size: 15px;
}

.game-countdown-modal h2 {
  font-size: 24px;
}

.countdown-number {
  font-size: 44px;
}

.withdraw-message.show {
  justify-content: center;
}

.withdraw-message.show svg{
  font-size: 14px;
}


.rocket-smoke {
  position: absolute;
  bottom: -100px; 
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 500px;
  background: radial-gradient(circle, rgba(255,255,255,0.6) 20%, rgba(255,255,255,0) 70%);
  opacity: 1;
  z-index: 0;
  animation: smoke-rise 4s forwards;
}







.controls-container {
  gap: 10px;
  position: fixed;
  z-index: 1000;
  display: flex !important;
  margin-top: 10px;
  padding: 10px;
}

.control-button {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.3s;
}


.control-button:active {
  transform: scale(0.95);
}

.control-button svg {
  pointer-events: none;
  margin-right: 0px;
}

.menu-line2 {
  display: flex !important;
  margin-top: 50px;
}

























.stelar-game-display-2 {
  position: relative;
  background-color: #000000;
  overflow: hidden;
  height: 400px !important;
  cursor: crosshair;
  border-radius: 10px;
  margin-top: 15px;
}

canvas {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.stelar-ship {
  position: absolute;
  top: 30px;
  left: 20px;
  width: 100px !important;
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-top: 25px;
  margin-left: 25px;
  
  /* Efect de plutire vertical */
  animation: floatt 3s ease-in-out infinite;
}

/* Animație de plutire de bază */
@keyframes floatt {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

/* Animație cu oscilație mai subtilă */
@keyframes floatt {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-5px); }
}

/* Animație cu mișcare mai complexă */
@keyframes floatt {
  0%, 100% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-8px) rotate(-2deg); }
  75% { transform: translateY(-5px) rotate(2deg); }
}

.stelar-ship-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.stelar-anchor {
  position: absolute;
  pointer-events: none;
  z-index: 5;
}

.stelar-anchor line {
  stroke-linecap: round; /* Colțuri rotunjite pentru funie */
}

.stelar-meteorite {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  z-index: 2;
}


.progress-stelar-container {
  position: relative;
  height: 10px !important;
  background-color: rgba(211, 211, 211, 0.105);
  margin: 10px auto;
  border-radius: 10px;
  overflow: hidden;
  backdrop-filter: blur(5px);
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  z-index: 10;
}

.progress-stelar-bar {
  height: 100%;
  background-color: rgb(144, 144, 189);
  transition: width 0.1s linear;
  z-index: 10;
}

.mini-stelar-ship {
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
}


.reward-messages-container {
  position: absolute;
  top: 80px; /* Distanță față de top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  pointer-events: none;
}

.reward-message {
  position: relative;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  animation: fadeAndRise 2s ease-out forwards;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

@keyframes fadeAndRise {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}


.start-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
}

.start-title {
  font-size: 30px !important;
  margin-bottom: 20px !important;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  margin-top: 0px;

}

.start-button {
  padding: 10px 20px;
  font-size: 15px !important;
  color: #fff;
  background: rgba(128, 128, 128, 0.145);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  backdrop-filter: blur(2px);
  font-weight: bold;
}

.start-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}







.end-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
}

.end-title {
  font-size: 30px !important;
  margin-bottom: 20px !important;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  margin-top: 0px;
  margin-bottom: 0px;

}

.stats-value {
  font-weight: 900;
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-bottom: 35px;
  font-size: 20px;
}

.stats-value svg {
  margin-right: 5px;
}






}

.menu-line2 {
  display: none;
}

























/* Responsive 375px */

@media (max-width: 375px) {

  .sidebar {
    width: 270px;
    
  }

  .info-modal {
    top: 55%;
  }

  .page-modal {
    top: 55%;
  }

  .game-tap-button {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stat-box p {
    font-size: 9px;
  }

  .stat-label svg {
    font-size: 10px;
  }

  .energy-icon {
    font-size: 17px;
  }

  .step-icon {
    font-size: 20px;
  }

  .step {
    padding: 20px;
  }

  .step-title {
    font-size: 12px;
    margin-top: 0px;
  }

  .progress-bar-container {
    height: 150px;
  }

  .bet-input {
    font-size: 15px;
  }

  .start-bet {
    font-size: 11px;
  }

  

  .step-description {
    font-size: 10px;
  }

  .task-details h3 {
    font-size: 12px;
  }

  .task-details p {
    font-size: 11px;
  }

  .task-btn, .task-item.completed {
    padding: 8px 8px;
    margin: 0px;
  }

  .task-info { 
    margin: 0px;
  }

  .button-container button {
    padding: 10px 20px;
  }

  .case-modal img {
    width: 70%;
  }

  .case-modal {
    top: 55%;
  }

  .rankup-modal {
    top: 36%;
  }

  .task-modal {
    top: 60%;
  }

  .task-modal-ad {
    top: 60%;
  }

  .modal-content {
    top: 30%;
  }

  .token-image {
    width: 40px;
    height: 40px;
  }

  .token {
    margin-bottom: 15px;
  }

  .token-name, .transaction-type {
    font-size: 13px;
  }

  .token-tag {
    font-size: 11px;
  }

  .token-price {
    font-size: 11px;
  }

  .token-change {
    font-size: 11px;
  }

  .token-amount {
    font-size: 12px;
  }

  .token-value {
    font-size: 11px;
  }

  .tokens h2 {
    font-size: 15px;
  }

  .transactions h2 {
    font-size: 15px;
  }

  .transaction-details {
    font-size: 11px;
  }
  
  .transaction-amount {
    font-size: 11px;
  }

  .tokens, .transactions {
    height: 200px;
  }

  .token-space, .transactions-space {
    height: 125px;
  }

  .nav-logo img {
    width: 165px;
    display: initial;
    opacity: 0.8;
  }

  .invite-progress span {
    background-color: #1e1e1e;
    padding: 8px 8px;
    color: white;
    cursor: default;
    margin: 0px;
    border-radius: 5px;
    font-size: 11px;
  }

  .referral-link-box p {
    font-size: 11px;
    left: 0% !important;
  }

  .referral-link-box button {
    font-size: 13px;
  }

  .login-card {
    display: inline;
    margin: 0px 10px;
    border-radius: 10px;
    
  }

  .login-image {
    width: 100%;
    height: 150px;
  }

  .login-logo{ 
    font-size: 21px;
  }

  .login-header {
    margin-bottom: 0;
    justify-content: start;
  }

  .login-body {
    padding: 2rem 1.3rem;
  }

  .login-button{ 
    font-size: 13px;
  }

  .login-input {
    font-size: 0.9rem;
  }





  .register-card {
    display: inline;
    margin: 0px 10px;
    border-radius: 10px;
    margin-bottom: 50px;
    margin-top: 10px;
  }
  
  .register-image {
    width: 100%;
    height: 150px;
  }
  
  .register-logo{ 
    font-size: 21px;
  }
  
  .register-header {
    margin-bottom: 0;
    justify-content: start;
  }
  
  .register-body {
    padding: 2rem 1.3rem;
  }

  .register-button{ 
    font-size: 13px;
  }

  .register-input, .login-input, .recovery-input {
    font-size: 11px;
    padding: 10px;
  }
  
  
  
  
  
  
  
  .recovery-card {
    display: inline;
    margin: 0px 10px;
    border-radius: 10px;
  }
  
  .recovery-image {
    width: 100%;
    height: 150px;
  }
  
  .recovery-logo{ 
    font-size: 25px;
  }
  
  .recovery-header {
    margin-bottom: 0;
    justify-content: start;
  }
  
  .recovery-body {
    padding: 2rem 1.3rem;
  }

  .recovery-button { 
    font-size: 0.9rem;
  }

  .recovery-input {
    font-size: 0.9rem;
  }
  
  .register-message {
    margin-top: 10px;
    font-size: 11px;
  }
  
  .login-message {
    margin-top: 10px;
    font-size: 13px;

  }
  
  .recovery-message {
    margin-top: 10px;
    font-size: 13px;

  }

  .recovery-login {
    font-size: 15px;
  }

  .login-register {
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .register-login {
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .stat-box {
    padding: 7px 7px;
    border-radius: 7px;
  }

  .energy-value {
    font-size: 15px;
  }

  .wallet-zone svg {
    font-size: 25px;
  }

  .xp-bar {
    height: 20px;
  }

  .game-xp-value {
    font-size: 10px;
  }

  .rank-image-container {
    height: 50px;
    width: 50px;
  }

  .game-end-modal {
    top: 60%;
  }

  .rocket-thrust {
    width: 60px;
    bottom: 28%;
  }

  .current-x-value {
    font-size: 17px;
    padding: 5px;
  }

  .rewards .item {
    height: 90px !important;
  }

  .page-modal-content p {
    font-size: 13px;
    line-height: 15px;
  }

  .info-modal-content p {
    font-size: 13px;
    line-height: 15px;
  }

  .rankup-modal-content p {
    font-size: 13px;
    line-height: 15px;
  }

  .task-modal-content p {
    font-size: 13px;
    line-height: 15px;
  }

  .task-modal-ad-content p {
    font-size: 13px;
    line-height: 15px;
  }

  .case-modal-content p {
    font-size: 13px;
    line-height: 15px;
  }

  .game-end-modal p {
    font-size: 13px;
    line-height: 15px;
  }

  .withdraw-message {
    font-size: 10px;
    padding: 8px 20px;
  }

  .loader-title {
    font-size: 17px !important;
    width: 90%;
    top: 20% !important;
    line-height: 25px;
  }

  .loader-button {
    padding: 10px 25px !important;
    font-size: 12px !important;
    color: #f4f4f4 !important;
  }

}










@media (max-width: 375px) {

  .rocket-thrust {
  }
}

@media (max-width: 390px) {

  .rocket-thrust {
  }
}

@media (max-width: 412px) {

  .rocket-thrust {
  width: 50px;
  bottom: 10%;
  }
}

@media (max-width: 430px) {

.referral-link-box p {
  font-size: 12px;
}
}










.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  position: fixed;
}
.lightrope li {
  position: relative;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: rgba(0, 247, 165, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
  animation-name: flash-1;
  animation-duration: 2s;
}
.lightrope li:nth-child(2n+1) {
  background: rgba(0, 255, 255, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
  animation-name: flash-2;
  animation-duration: 0.4s;
}
.lightrope li:nth-child(4n+2) {
  background: rgba(247, 0, 148, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
  animation-name: flash-3;
  animation-duration: 1.1s;
}
.lightrope li:nth-child(odd) {
  animation-duration: 1.8s;
}
.lightrope li:nth-child(3n+1) {
  animation-duration: 1.4s;
}
.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.3333333333px;
  border-radius: 3px;
  top: -4.6666666667px;
  left: 1px;
}
.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.6666666667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}
.lightrope li:last-child:after {
  content: none;
}
.lightrope li:first-child {
  margin-left: -40px;
}
@keyframes flash-1 {
  0%, 100% {
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
 }
  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
 }
}
@keyframes flash-2 {
  0%, 100% {
    background: rgba(0, 255, 255, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 1);
 }
  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
 }
}
@keyframes flash-3 {
  0%, 100% {
    background: rgba(247, 0, 148, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
 }
  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
 }
}




.intro,
.intro a{
  color:#fff;
}
/* customizable snowflake styling */
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
  pointer-events: none;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
	color:#fff;
    display: block;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
}
.demo a{
  font-family: 'Raleway', sans-serif;
color: #000;		
}





/* Loader Container */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  overflow: hidden;
}

/* Loader Image */
.loader-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-in 1.5s ease-out;
}


/* Button */
.loader-button {
  position: absolute;
  bottom: 5%;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  cursor: pointer;
  z-index: 1;
}



/* Loaded Animation */
.loader-container.loaded .loader-image {
  animation: open-door 2s forwards ease-in-out;
}

/* Home Content */
.home-content {
  padding: 20px;
  animation: fade-in 1.5s ease-out;
}

/* Animations */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* From Uiverse.io by arshshaikh06 */ 
.spinner2 {
  background-image: linear-gradient(rgba(186, 66, 255, 0) 35%,rgba(0, 225, 255, 0));
  width: 100px;
  height: 100px;
  animation: spinning82341 1.7s linear infinite, hue 1s ease-in-out infinite;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px -5px 20px 0px rgb(186, 66, 255), 0px 5px 20px 0px rgb(0, 225, 255);

}

.spinner1 {
  background-color: rgba(36, 36, 36, 0.123);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  filter: blur(10px);
}

@keyframes spinning82341 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}





/* From Uiverse.io by cssbuttons-io */ 
.loader-button {
  --glow-color: rgb(255, 203, 99);
  --glow-spread-color: rgba(255, 219, 113, 0.781);
  --enhanced-glow-color: rgb(255, 249, 227);
  --btn-color: rgba(253, 209, 86, 0.209);
  border: 1px solid var(--glow-color);
  padding: 1.5em 2.5em;
  color: var(--enhanced-glow-color);
  font-size: 15px;
  font-weight: bold;
  background-color: var(--btn-color);
  border-radius: 1em;
  outline: none;
  box-shadow: 0 0 1em .25em var(--glow-color),
         0 0 3em 1em var(--glow-spread-color),
         inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
  position: relative;
  transition: all 0.3s;
  backdrop-filter: blur(5px);
 }
 
 .loader-button::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(1em);
  opacity: 1;
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
 }
 

 /* Logo-ul din colțul stânga sus */
.loader-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px; /* Ajustează dimensiunea logo-ului */
  height: auto;
  z-index: 10;
}

/* Titlu mare deasupra loaderului */
.loader-title {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 56px; /* Ajustează dimensiunea textului */
  font-weight: bold;
  color: #ffffff;
  text-shadow: #FC0 1px 0 10px;  text-align: center;
  z-index: 10;
  letter-spacing: 1.5px;
  font-family:Georgia, 'Times New Roman', Times, serif;
}

/* Text jos în partea de jos */
.loader-footer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  z-index: 10;
  opacity: 0.8;
}




/* src/components/SoundAndVibrationControls.css */
.controls-container {
  display: none;
}



.game-tap-button::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), var(--currentGlowColor) 110%);
  transition: background 0.5s ease-in-out;
  box-shadow: 0 0 20px var(--currentGlowColor), 0 0 5px var(--currentGlowColor);
}
















.stelar-game-display-2 {
  position: relative;
  background-color: #000000;
  overflow: hidden;
  height: 500px;
  cursor: crosshair;
  border-radius: 10px;
  margin-top: 15px;
}

canvas {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.stelar-ship {
  position: absolute;
  top: 30px;
  left: 20px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-top: 25px;
  margin-left: 25px;
  
  /* Efect de plutire vertical */
  animation: floatt 3s ease-in-out infinite;
}

/* Animație de plutire de bază */
@keyframes floatt {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

/* Animație cu oscilație mai subtilă */
@keyframes floatt {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-5px); }
}

/* Animație cu mișcare mai complexă */
@keyframes floatt {
  0%, 100% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-8px) rotate(-2deg); }
  75% { transform: translateY(-5px) rotate(2deg); }
}

.stelar-ship-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.stelar-anchor {
  position: absolute;
  pointer-events: none;
  z-index: 5;
}

.stelar-anchor line {
  stroke-linecap: round; /* Colțuri rotunjite pentru funie */
}

.stelar-meteorite {
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  z-index: 2;
}


.progress-stelar-container {
  position: relative;
  height: 20px;
  background-color: rgba(211, 211, 211, 0.105);
  margin: 10px auto;
  border-radius: 10px;
  overflow: hidden;
  backdrop-filter: blur(5px);
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  z-index: 10;
}

.progress-stelar-bar {
  height: 100%;
  background-color: rgb(144, 144, 189);
  transition: width 0.1s linear;
  z-index: 10;
}

.mini-stelar-ship {
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
}


.reward-messages-container {
  position: absolute;
  top: 80px; /* Distanță față de top */
  left: 70%;
  transform: translateX(-50%);
  z-index: 1000;
  pointer-events: none;
}

.reward-message {
  position: relative;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  animation: fadeAndRise 2s ease-out forwards;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

@keyframes fadeAndRise {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}


.start-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
}

.start-title {
  font-size: 40px;
  margin-bottom: 30px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  margin-top: 0px;

}

.start-button {
  padding: 10px 20px;
  font-size: 20px;
  color: #fff;
  background: rgba(128, 128, 128, 0.145);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  backdrop-filter: blur(2px);
  font-weight: bold;
}

.start-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}







.end-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
}

.end-title {
  font-size: 40px;
  margin-bottom: 30px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  margin-top: 0px;
  margin-bottom: 0px;

}

.stats-value {
  font-weight: 900;
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-bottom: 35px;
  font-size: 20px;
}

.stats-value svg {
  margin-right: 8px;
  margin-left: 18px;
}





